export const New = () => {
  return (
    <div className="new">
      <p className="new__title">What's news?</p>
      <p className="new__description">
        The bonuses and promotions section has been updated.
      </p>
      <p className="new__description">
        The location of the nearest branch, ATM or terminal is added to the map.
      </p>
      <p className="new__description">
        Face ID and Touch ID support has been added.
      </p>
      <p className="new__description">
        Fixed an issue with push notifications that some Android 10 users were
        experiencing.
      </p>
      <p className="new__description">
        Fixed an issue where the text in Settings was too small for Android
        users.
      </p>
    </div>
  );
};
